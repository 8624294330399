<template>
  <CHeader fixed with-subheader primary>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
    <div class="w-100">
 <!-- <img src="@/assets/icons/logo-sm.png" class="img-fluid p-1" alt=""> -->
      <b-badge variant="success">{{name_establishment}}</b-badge>
    </div>
     
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
        <b-badge variant="info">{{name_bussines}}</b-badge>
        <b-badge class="ml-2" variant="info">{{name_establishment}}</b-badge>
        <b-badge v-if="service_expiration.length > 0" class="ml-2" variant="warning">{{service_expiration}} </b-badge>
    </CHeaderNav>
    <CHeaderNav class="d-md-down-none mr-1">
      <b-badge variant="success">{{user.user}}</b-badge>
    </CHeaderNav>
    <CHeaderNav class="d-md-down-none mx-2">
      <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0" >
        <template #toggler>
        <CHeaderNavLink :class="(not_read > 0 ? 'animated flash':'')">
          <div class="c-avatar">
            <img src="@/assets/icons/email.png" class="img-float" /> <b-badge variant="info">{{not_read}}</b-badge>
          </div>
        </CHeaderNavLink>
      </template>
      <div class="dropdown-menu dropdown-menu-end dropdown-menu-lg pt-0 show"
        style="position: absolute; inset: 0px 0px auto auto; margin: 0px; min-width: 400px;" data-popper-placement="bottom-end">
        <div class="dropdown-header bg-light dark:bg-white dark:bg-opacity-10"><strong>Tienes {{not_read}} mensajes sin leer</strong></div>
        <a @click="ViewMessage(item.id_message)" v-for="(item, it) in messages" :key="it" :class="'dropdown-item '+ (item.read == 0 ? 'bg-warning':'bg-white')" >
            <div class="col-md-12 row" >
              <div class="col-md-12">
                <small class="text-medium-emphasis float-left">{{item.from}}</small>
                <small class="text-medium-emphasis float-right">{{item.created_at}}</small>
              </div>
              <div class="col-md-12">
                <div class="text-truncate font-weight-bold">
                   {{item.subject}}
                </div>
                <div class="small text-medium-emphasis text-truncate">
                  {{item.message}}
                </div>
              </div>
            </div>
       
      
        </a>
      </div>


      <b-modal size="md" hide-footer v-model="modal_message" class="w-100" :title="message.title">
        <div style="white-space: pre-line;">
          {{ message.text }}
        </div>
        
        <div class="w-100 text-center" >
          <br>
          <b-button type="button" size="sm" @click="ReadMessage" variant="info" >Marcar como leido</b-button>
        </div>

      </b-modal>

  
    <b-modal size="lg" :no-close-on-backdrop="!continue_service" :no-close-on-esc="!continue_service" hide-footer :hide-header-close="!continue_service" v-model="modal_expiration" class="w-100" title="CORTE DE SERVICIO">
              <b-alert variant="danger" class="text-center" show><h5>{{ service_expiration }}</h5></b-alert>
    </b-modal>
  
    </CDropdown>

    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>

  </CHeader>
</template>
<style lang="css" scoped>
.animated {
 -webkit-animation-duration: 2s;
 animation-duration: 2s ;
 -webkit-animation-fill-mode: both;
 animation-fill-mode: both;
 animation-iteration-count: infinite;
}
@-webkit-keyframes flash {
 0%, 50%, 100% {
 opacity: 1;
 }
25%, 75% {
 opacity: 0;
 }
}
@keyframes flash {
 0%, 50%, 100% {
 opacity: 1;
 }
25%, 75% {
 opacity: 0;
 }
}
.flash {
 -webkit-animation-name: flash;
 animation-name: flash;
}
</style>
<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
const je = require("json-encrypt");
const axios = require("axios").default;
import { mapState,mapActions } from "vuex";

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  },
  data() {
    return {
      name_establishment: "",
      name_bussines:"",
      expiration: false,
      modal_expiration: false,
      service_expiration: '',
      continue_service: true,
      messages:[],
      not_read:0,
      modal_message: false,
      message: {
        id_message:'',
        title :'',
        text:'',
      }
    };
  },
  methods: {
    ViewEstablishment,
    ViewBussiness,
    ServiceExpiration,
    ListMessages,
    ViewMessage,
    ReadMessage,
  },
  mounted() {
    this.ViewEstablishment();
    this.ViewBussiness();
    this.ServiceExpiration();
    this.ListMessages();

    this.message.id_message = '';
    this.message.title = '';
    this.message.text = '';
  },
  computed: {
    ...mapState(["url_base"]),
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
}
function ViewEstablishment() {

  let me = this;
  let url = this.url_base + "view-establishment/"+this.id_establishment;

  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.name_establishment = response.data.result.name;
      window.localStorage.removeItem('business');
      let business = {
        type_print : response.data.result.type_print,
        type_print_rg : response.data.result.type_print_rg,
      }
      business = je.encrypt(JSON.stringify(business));
      window.localStorage.setItem( "business",business );
    }
  })

}

function ListMessages() {

let me = this;
let url = this.url_base + "message/list";

axios({
  method: "GET",
  url: url,
  headers: { token: this.token },
})
.then(function (response) {
  if (response.data.status == 200) {
    me.messages = response.data.messages;
    me.not_read = parseFloat(response.data.not_read);
  }
})

}

function ViewBussiness() {
  let me = this;
  let url = this.url_base + "get-business";

  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,},
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.name_bussines = response.data.result.name+" - "+response.data.result.document_number;

      window.localStorage.removeItem('type_business');
      window.localStorage.removeItem('paid');
      window.localStorage.removeItem('type_search_product');
      window.localStorage.removeItem('seller');
      window.localStorage.removeItem('alternative_reports');
      let type_business = {
        type_business : response.data.result.type_business,
      }
      type_business = je.encrypt(JSON.stringify(type_business));
      window.localStorage.setItem( "type_business",type_business );
      window.localStorage.setItem( "paid",response.data.result.paid );
      window.localStorage.setItem( "type_search_product",response.data.result.type_search_product );
      window.localStorage.setItem( "seller",response.data.result.seller );
      window.localStorage.setItem( "alternative_reports",response.data.result.alternative_reports );
 
      
    }
  })
}

function ServiceExpiration() {
  let me = this;
  let url = this.url_base + "service-expiration";

  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,},
  })
  .then(function (response) {
    if (response.data.status == 200) {

      me.continue_service = response.data.result.continue_service;
      me.modal_expiration = response.data.result.expiration;    
      me.expiration = response.data.result.expiration;
      me.service_expiration = response.data.result.service_expiration;

    }
  })
}

function ViewMessage(id_message) {
  let me = this;
  let url = this.url_base + "message/view-message/"+ id_message;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,},
  })
  .then(function (response) {
    if (response.data.status == 200) {
        me.modal_message = true;
        me.message.id_message = response.data.result.id_message;
        me.message.title = response.data.result.subject;
        me.message.text = response.data.result.message;
        //me.not_read = parseFloat(me.not_read) - 1;
    }
  })
}

function ReadMessage() {
  let me = this;
  let url = this.url_base + "message/read-message/"+ this.message.id_message;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,},
  })
  .then(function (response) {
    if (response.data.status == 200) {
        me.modal_message = false;
        me.message.id_message = '';
        me.message.title = '';
        me.message.text = '';
     

        me.ListMessages();
    }
  })
}

</script>
<style>

</style>